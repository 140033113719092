import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const FeatureBenefits = () => {


  return (
    <section className="customs tokenize pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Features Of </span> Asset Tokenization Development
            </h2>
            <p className='text-center'>Unlock the potential of tokenomy with the features of our Asset Tokenization Development services.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Multi-Asset Support</h3>
                    <p class="pharagraph">A wide range of traditional illiquid assets can be tokenized to earn profits. This offers a wide variety of options from real estate to intellectual properties like patents that can be tokenized to earn monetary benefits.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Direct Engagement</h3>
                    <p class="pharagraph">No involvement of middlemen or central authority is there to regulate and the smart contracts incorporated enable direct peer-to-peer interaction between the asset owners and the investor for carrying out the trading and transactions.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Record Keeping</h3>
                    <p class="pharagraph">All transactions are recorded and stored in the common distributed ledger which is immutable. The transactions are cryptographically stored, verified, validated, and secured with robust safeguarding measures.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Traceability</h3>
                    <p class="pharagraph">The asset tokenization platform offers end-to-end traceability of assets in the platform. From providing previous transaction history to activity done over the assets, facilitating a secure experience preventing scams.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Smart Contracts</h3>
                    <p class="pharagraph">Smart contracts self-regulate and carry out the entire working mechanism of the platform, eliminating third-party intervention. With the pre-designed codes, the smart contracts offer a self-secure way to carry out activities.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Customizable</h3>
                    <p class="pharagraph">Coinsclone’s Tokenization Platform development comes with the integration of astonishing features and advanced customizable options. Based on the client requirements the platform development can be customized.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default FeatureBenefits