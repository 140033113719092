import React from 'react'



class VariousBlock extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use tokenize pt-100 secureof">
        <div className="container">
          <h2 className="main-heading">Our Asset Tokenization <span className='bluecolor'>Development Services</span></h2>
          <p className='text-center'>Revolutionize your business by availing of our Asset Tokenization platform Development Services. Fuel your future career goals to the next level by exploring every nook and corner of asset management.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Token development
                  </h3>
                <p className="pharagraph">Empower the potential of your digital assets by tokenizing them with our trusted crypto engineers. Tokenize any kind of digital valuables to tradable tokens and add value to your assets and trade them. Our team offers a secure, transparent, and efficient tokenization process. Whether it's a meme, stock, bond, or any kind of digital asset you can securely and efficiently tokenize them to generate revenue.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Platform development
                </h3>
                <p className="pharagraph">With Coinsclone’s Tokenization platform development crave your presence in the crypto realm. Your digital tokenization platform will act as a bridge to connect interested users with innovative digital collectibles and artworks. The technological advancements can be tailored here to best fit your strategic business moves. Our platform is built with an attractive and intuitive interface offering a secure and easy user experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart Contract Integration
                </h3>
                <p className="pharagraph">Make your platform autonomous and self-regulated by integrating essential smart contracts with a prompt blockchain network. We transform the complexities of programs into customizable codes offering immutability, security, and scalability.  This is specifically designed by our crypto engineers to meet your business requirements. Our smart contracts provide the foundation for transparent and automated transactions.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Support & Maintenance
                </h3>
                <p className="pharagraph">Keeping it current and matching the latest trends and advancements is a challenging task. Our developers offer comprehensive technical, and strategic post-deployment support. We ensure the smooth functioning of the platform by monitoring the platform and addressing any kind of issues. Navigate the ever-changing landscape of technology confidently with our extensive maintenance and support team.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default VariousBlock