import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class DevProcess extends React.Component {

  render() {
    return (
      <section className="process-of tokenize pt-100 mb-0">
            <div className="container">     
              <h2 className="heading-h2 text-center"><span className="bluecolor">Business Benefits </span> Of Digital Asset Tokenization Development
              </h2>
              <p className="text-center">
              Explore the unparalleled benefits the asset tokenization development brings to investors, users, and the global economy. From attracting new investors to altering the financial impact our asset tokenization development services have a greater impact on reshaping the future.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <h3>Global Access</h3>
                  <p className="pharagraph">
                  Asset tokenization development is the key to reaching global audiences. This breaks down the geographical barriers and regulatory considerations enhancing cross-border transactions and attracting a wide range of potential users.
                  </p>
                </div>
                <div className="square">
                  <h3>Transparency</h3>
                  <p className="pharagraph">
                  The blockchain’s core functionality makes the record of transactions on a distributed ledger that facilitates transparency and immutability of transactions. Thus, transparency reduces the risk of fraud and increases trust among participants.
                  </p>
                </div>
                <div className="square" >
                  <h3>Fractional Ownership</h3>
                  <p className="pharagraph">
                  Without needing to purchase the whole digital assets, the users can just own a part or a little division of digital assets. This opens up a door to retail trading, allowing accessibility and attracting a diverse range of users to invest.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square" >
                  <h3>Efficient Transfers</h3>
                  <p className="pharagraph">
                  Contrary to the time-consuming traditional process, our platform enables users to efficiently engage in asset ownership transfers. We streamline the platform with robust operational structures, and the process can be completed swiftly.
                  </p>
                </div>
                <div className="square">
                  <h3>Enhances Liquidity</h3>
                  <p className="pharagraph">
                  The Ownership of holding assets in the smaller fractions enhances liquidity all over the platform making it easily accessible. This provides high liquidity for the traditionally illiquid assets and facilitates more efficient trading.
                  </p>
                </div>
                <div className="square" >
                  <h3>Vast Investment Spectrum</h3>
                  <p className="pharagraph">
                  The tokenization of traditional illiquid assets can open your door to innovation and the wide spectrum of options available. This provides an opportunity to own up inaccessible assets providing numerous monetary benefits.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
            </div>
      </section>

    )
  }
}

export default DevProcess
