import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">Why Prefer Coinsclone As Your <span className="bluecolor">Asset Tokenization Platform Development Partner?</span></h2>
              <p className="pharagraph">Coinsclone is a reputable Asset Tokenization Platform development company that provides crypto-related services with utmost client satisfaction. We, with our market experts and professionals, have been giving life to the crypto business dreams of our clients. Offering asset development services for nearly a decade, we take pride in being the reliable, innovative developers in the industry.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/asset-token/why-prefer-asset-tokenization.webp"
                alt="best Asset Tokenization Platform development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={532}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Utilize our services for your guaranteed career growth. Our dedication and commitment to our project work made our clients think of us as their reliable partners. Our successful project portfolio stands as a milestone. Let's pave the way for a future where assets are more accessible and tradable.  We empower businesses to harness and capitalize on the power of tokenization with digital advancement.
              </p>
              <b>Instead of predicting future opportunities join us in creating it</b>
              <ul className='mt-3'>
                <li>24/7 reliable customer support.</li>
                <li>75+ well-experienced market experts.</li>
                <li>Budgetary solution and on-time delivery.</li>
                <li>Guaranteed & fully customizable solutions.</li>
                <li>Conception to deployment all under one roof. </li>
                <li>Transparent pricing and development systems.</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose