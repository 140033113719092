import React from "react"

class Benificial extends React.Component {

  render() {
    return (
      <section className="fine-box tokenize pt-100 mb-0 pb-3">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our Asset Tokenization <span className="bluecolor">Development Approach</span>
            </h2>
            <p className="text-center mb-2">
            We at Coinsclone follow a systematic approach to developing your project. Our developers, consultants, and designers work closely to drive innovation into new opportunities.  By offering comprehensive Asset Tokenization development services we deliver scalable, secured solutions customized to your table.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Ideation</h3>
                <p className="pharagraph">
                Coinsclone’s Asset Tokenization Development process begins by gathering the client’s business requirements. We engage in one-on-one sessions with clients to determine the strategy, aligning our end project goals with these requirements.
                </p>
              </div>
              <div className="square" >
                <h3>Designing</h3>
                <p className="pharagraph">
                After the requirements collection, the next step is designing. The framework of the asset tokenization platform is set here. Our handpicked expert designers will fix the basic layout and craft your platform incorporating an intuitive interface that grabs the attention.
                </p>
              </div>
              <div className="square" >
                <h3>Development</h3>
                <p className="pharagraph">
                Next is the crucial stage in the entire Development process. After the layout fixation, the development process is carried out. Encompassing essential tasks such as smart contract integration, and front-end and back-end development are done here.
                </p>
              </div>
              <div className="square" >
                <h3>Tech integration</h3>
                <p className="pharagraph">
                The legal and regulatory compliances are reviewed and addressed. The smart contract integration to self-regulation and APIs are incorporated for compatibility. Deciding the tokenomics, rules for ownership rights, and governance mechanisms decisions are made here.
                </p>
              </div>
              <div className="square" >
                <h3>Testing</h3>
                <p className="pharagraph">
                After the development stage, there goes the testing process. With our team of Quality Assurance and Software Testers, we involve the platform in frequent testing. This stage ensures in identifying, eliminating bugs, or fixing any glitches that arise in the platform.
                </p>
              </div>
              <div className="square" >
                <h3>Deployment</h3>
                <p className="pharagraph">
                Coinsclone helps you effortlessly launch and deploy the developed product correctly on the suited and required platforms. With our market experts, we work to provide you with a seamless experience eliminating every hurdle in the deployment stage. 
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default Benificial
